import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Country } from 'src/app/models/country.model';
import { Currency } from 'src/app/models/currency.model';
import { MarketParameters } from 'src/app/models/marketparameters.model';
import { MarketUnit } from 'src/app/models/marketunit.model';
import { ProductSeries } from 'src/app/models/models/productseries.model';
import { AccessService } from 'src/app/service/api/access.service';
import { CurrencyService } from 'src/app/service/api/currency.service';
import { GeoService } from 'src/app/service/api/geo.service';
import { MarketParameterService } from 'src/app/service/api/market-parameter.service';
import { MarketService } from 'src/app/service/api/market.service';
import { SharedService } from 'src/app/service/shared.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-market-parameter',
  templateUrl: './market-parameter.component.html',
  styleUrls: ['./market-parameter.component.css'],
})
export class MarketParameterComponent {
  marketParentList: MarketUnit[] = [];
  marketParameterList: MarketParameters[] = [];
  currencyList: Currency[] = [];
  productSeriesList: ProductSeries[] = [];
  countryList: Country[];

  currentDateAndTime = this.datePipe.transform(
    new Date(),
    'yyyy-MM-dd HH:mm:ss'
  );
  parameterId: number;
  partnerId: number = parseInt(this.shared.getPartnerId());
  requestType = 'POST';

  formMarketParam: FormGroup;

  systems = [{ Name: 'MUSYS-BR' }, { Name: 'MUSYS-LA' }];

  isBusinessList = [
    { Value: 0, Description: 'True' },
    { Value: 1, Description: 'False' },
  ];

  constructor(
    public shared: SharedService,
    private market: MarketService,
    private marketParameters: MarketParameterService,
    private currency: CurrencyService,
    private access: AccessService,
    private formBuilder: FormBuilder,
    private cookieService: CookieService,
    private geo: GeoService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.getListByUsers();
    this.getMarketParameters();
    this.getCurrencies();
    this.getUserProductSeries(this.partnerId);
    this.getCountries();
    this.createMarketParamForm();
  }

  get general() {
    return this.formMarketParam.get('General')['controls'];
  }

  getUserProductSeries(partnerId: number) {
    this.access.getUserProductSeries(partnerId).subscribe({
      next: (result) => {
        this.productSeriesList = result.Data;
      },
    });
  }

  getListByUsers() {
    this.market.listByUser().subscribe({
      next: (result) => {
        this.marketParentList = result.Data;
      },
    });
  }

  getMarketParameters() {
    this.marketParameters.get().subscribe({
      next: (result) => {
        this.marketParameterList = result.Data;
      },
    });
  }

  getCurrentMarketParam(parameterId, modal) {
    this.marketParameters.getCurrentMarketParam(parameterId).subscribe({
      next: (result) => {
        var currentMarket = result.Data;
        this.edit(currentMarket, modal);
      },
    });
  }

  getCurrencies() {
    this.currency.listCurrencies().subscribe({
      next: (result) => {
        this.currencyList = result.Data;
      },
    });
  }

  getCountries() {
    this.geo.listCountries().subscribe({
      next: (result) => {
        this.countryList = result.Data;
      },
    });
  }

  createMarketParamForm() {
    document.getElementById('save-button').style.visibility = 'visible';
    this.requestType = 'POST';
    this.formMarketParam = this.formBuilder.group({
      General: this.formBuilder.group({
        Id: [0, Validators.required],
        MarketId: [
          this.cookieService.get('Musys.Ui.User.MarketUnitId.Current'),

          Validators.required,
        ],
        ProductTypeId: [, Validators.required],
        ProductType: ['', Validators.required],
        IsDefault: [''],
        DefaultCurrencyCode: ['', Validators.required],
        System: ['', Validators.required],
        ProductLanguage: ['', Validators.required],
        CountryId: [, Validators.required],
        ScobProductTypeId: [, Validators.required],
        UpdatedBy: [this.shared.CurrentUsername, Validators.required],
        LastUpdated: [this.currentDateAndTime, Validators.required],
      }),
      Specification: this.formBuilder.group({
        System: ['Etel'],
        Screen: [''],
        MarketId: [],
        MarketName: [''],
        OrderMarket: [],
        IsBusinessUnit: [null],
        IsoCountryCode: [null],
        LanguageId: [],
        UserId: [],
        DefaultViewId: [],
        ConfigTypeId: [],
      }),
      Price: this.formBuilder.group({
        System: ['Etel'],
        MarketCode: [''],
        FactoryInvoiceTypeId: [9],
        FactoryMarketTypeId: [99],
        DealerTypeId: [],
        RetailTypeId: [],
      }),
      IacobParameters: this.formBuilder.group({
        MarketId: [],
        ParameterId: [],
      }),
    });
  }

  setHeaderNames() {
    document.getElementById('id').setAttribute('column-title', 'Id');
    document
      .getElementById('partnerId')
      .setAttribute('column-title', this.shared.translate('PartnerId'));
    document
      .getElementById('productTypeId')
      .setAttribute('column-title', this.shared.translate('Product Type Id'));
    document
      .getElementById('productType')
      .setAttribute('column-title', this.shared.translate('Product Type'));
    document.getElementById('info').setAttribute('column-title', '');
    document.getElementById('edit').setAttribute('column-title', '');
    document.getElementById('delete').setAttribute('column-title', '');
  }

  setTableValues(index: number) {
    document
      .getElementById(`rowId${index}`)
      .setAttribute(
        'cell-value',
        this.marketParameterList[index].Id.toString()
      );
    document
      .getElementById(`rowPartner${index}`)
      .setAttribute(
        'cell-value',
        this.marketParameterList[index].MarketId.toString()
      );
    document
      .getElementById(`rowProductId${index}`)
      .setAttribute(
        'cell-value',
        this.marketParameterList[index].ProductTypeId.toString()
      );
    document
      .getElementById(`rowProduct${index}`)
      .setAttribute('cell-value', this.marketParameterList[index].ProductType);
    document.getElementById(`rowInfo${index}`).setAttribute('cell-value', '');
    document.getElementById(`rowEdit${index}`).setAttribute('cell-value', '');
    document.getElementById(`rowDelete${index}`).setAttribute('cell-value', '');
  }

  setDropdown(dropdown: string, event: any) {
    switch (event.currentTarget.id) {
      case 'vehicleTypeId':
        this.formMarketParam
          .get('General')
          .get('ProductTypeId')
          .patchValue(event.target.value);

        this.formMarketParam
          .get('General')
          .get('ProductType')
          .patchValue(
            this.productSeriesList.find((p) => p.Id == event.target.value)
              .Description
          );
        break;
      case 'currency':
        this.formMarketParam
          .get('General')
          .get('DefaultCurrencyCode')
          .patchValue(event.target.value);
        break;
      case 'generalSystem':
        this.formMarketParam
          .get('General')
          .get('System')
          .patchValue(event.target.value);
        break;
      case 'isBusinessUnit':
        this.formMarketParam
          .get('Specification')
          .get('IsBusinessUnit')
          .patchValue(event.target.value);
        break;
      case 'IsoCountryCode':
        this.formMarketParam
          .get('Specification')
          .get('IsoCountryCode')
          .patchValue(event.target.value);
        break;
    }
  }

  edit(currentMarket: any, modal: any) {
    this.requestType = 'PUT';
    this.formMarketParam.patchValue({ General: { Id: currentMarket.Id } });
    this.formMarketParam.patchValue({
      General: { MarketId: currentMarket.MarketId },
    });
    this.formMarketParam.patchValue({
      General: { ProductTypeId: currentMarket.ProductTypeId },
    });
    document
      .getElementById('vehicleTypeId')
      ['setValue'](
        currentMarket.ProductTypeId,
        this.productSeriesList.find((p) => p.Id == currentMarket.ProductTypeId)
          .Description
      );
    this.formMarketParam.patchValue({
      General: { ProductType: currentMarket.ProductType },
    });
    document
      .getElementById('vehicleTypeId')
      ['setValue'](
        currentMarket.ProductType,
        this.productSeriesList.find(
          (p) => p.Description == currentMarket.ProductType
        ).Description
      );
    this.formMarketParam.patchValue({
      General: { IsDefault: currentMarket.IsDefault },
    });
    this.formMarketParam.patchValue({
      General: { DefaultCurrencyCode: currentMarket.DefaultCurrencyCode },
    });
    document
      .getElementById('currency')
      ['setValue'](
        currentMarket.DefaultCurrencyCode,
        this.currencyList.find(
          (c) => c.IsoCode == currentMarket.DefaultCurrencyCode
        ).IsoCode
      );
    this.formMarketParam.patchValue({
      General: { System: currentMarket.System },
    });
    document
      .getElementById('generalSystem')
      ['setValue'](
        currentMarket.System,
        this.systems.find((s) => s.Name == currentMarket.System).Name
      );
    this.formMarketParam.patchValue({
      General: { ProductLanguage: currentMarket.ProductLanguage },
    });
    this.formMarketParam.patchValue({
      General: { CountryId: currentMarket.CountryId },
    });
    this.formMarketParam.patchValue({
      General: { ScobProductTypeId: currentMarket.ScobProductTypeId },
    });
    this.formMarketParam.patchValue({
      General: { UpdatedBy: currentMarket.UpdatedBy },
    });
    this.formMarketParam.patchValue({
      General: { LastUpdated: currentMarket.LastUpdated },
    });
    this.formMarketParam.patchValue({
      Specification: { System: currentMarket.Specification.System },
    });
    this.formMarketParam.patchValue({
      Specification: { Screen: currentMarket.Specification.Screen },
    });
    this.formMarketParam.patchValue({
      Specification: { MarketId: currentMarket.Specification.MarketId },
    });
    this.formMarketParam.patchValue({
      Specification: { MarketName: currentMarket.Specification.MarketName },
    });
    this.formMarketParam.patchValue({
      Specification: {
        IsBusinessUnit: currentMarket.Specification.IsBusinessUnit,
      },
    });

    //validação pq ele nao passa nos dropdowns se tiver vazio
    if (currentMarket.Specification.IsBusinessUnit != null) {
      this.formMarketParam.patchValue({
        Specification: {
          IsBusinessUnit: currentMarket.Specification.IsBusinessUnit,
        },
      });
      document
        .getElementById('isBusinessUnit')
        ['setValue'](
          currentMarket.Specification.IsBusinessUnit,
          this.isBusinessList.find(
            (b) => b.Value == currentMarket.Specification.IsBusinessUnit
          ).Description
        );
    }

    this.formMarketParam.patchValue({
      Specification: {
        IsoCountryCode: currentMarket.Specification.IsoCountryCode,
      },
    });
    //validação aqui tb
    if (currentMarket.Specification.IsoCountryCode != null) {
      this.formMarketParam.patchValue({
        Specification: {
          IsoCountryCode: currentMarket.Specification.IsoCountryCode,
        },
      });

      document
        .getElementById('IsoCountryCode')
        ['setValue'](
          currentMarket.Specification.IsoCountryCode,
          this.countryList.find(
            (s) => s.Code == currentMarket.Specification.IsoCountryCode
          ).Code
        );
    }

    this.formMarketParam.patchValue({
      Specification: { LanguageId: currentMarket.Specification.LanguageId },
    });
    this.formMarketParam.patchValue({
      Specification: { UserId: currentMarket.Specification.UserId },
    });
    this.formMarketParam.patchValue({
      Specification: {
        DefaultViewId: currentMarket.Specification.DefaultViewId,
      },
    });
    this.formMarketParam.patchValue({
      Specification: { ConfigTypeId: currentMarket.Specification.ConfigTypeId },
    });

    this.formMarketParam.patchValue({
      Price: { System: currentMarket.Price.System },
    });
    this.formMarketParam.patchValue({
      Price: { MarketCode: currentMarket.Price.MarketCode },
    });
    this.formMarketParam.patchValue({
      Price: { FactoryInvoiceTypeId: currentMarket.Price.FactoryInvoiceTypeId },
    });
    this.formMarketParam.patchValue({
      Price: { FactoryMarketTypeId: currentMarket.Price.FactoryMarketTypeId },
    });
    this.formMarketParam.patchValue({
      Price: { DealerTypeId: currentMarket.Price.DealerTypeId },
    });

    this.formMarketParam.patchValue({
      IacobParameters: {
        MarketId: currentMarket.IacobParameters.MarketId || null,
      },
    });
    this.formMarketParam.patchValue({
      IacobParameters: {
        ParameterId: currentMarket.IacobParameters.ParameterId || null,
      },
    });
    modal.showModal('newMarketParam');
  }

  info(parameterId, modal: any) {
    this.formMarketParam.disable();
    this.getCurrentMarketParam(parameterId, modal);
    document.getElementById('save-button').style.visibility = 'hidden';
  }

  close(
    modal: any,
    productDropdown?: any,
    currencyDropdown?: any,
    systemDropdown?: any,
    businessDropdown?: any,
    countryDropdown?: any
  ) {
    this.formMarketParam.reset();
    this.createMarketParamForm();
    modal.closeModal();
    if (productDropdown) productDropdown.reset();
    if (currencyDropdown) currencyDropdown.reset();
    if (systemDropdown) systemDropdown.reset();
    if (businessDropdown) businessDropdown.reset();
    if (countryDropdown) countryDropdown.reset();
    this.requestType = 'POST';
  }

  save(modal: any) {
    if (this.requestType == 'POST') {
      this.create(modal);
    } else {
      this.update(modal);
    }
  }

  create(modal: any) {
    if (!this.formMarketParam.valid) {
      this.formMarketParam.markAllAsTouched();
      this.shared.errorAlert(
        this.shared.translate('Please, fill all the required fields!')
      );
      return;
    }

    var request = {
      ProductTypeId: parseInt(
        this.formMarketParam.get('General').get('ProductTypeId')?.value
      ),
      DefaultCurrencyCode: this.formMarketParam
        .get('General')
        .get('DefaultCurrencyCode')?.value,
      System: this.formMarketParam.get('General').get('System')?.value,
      ProductLanguage: this.formMarketParam
        .get('General')
        .get('ProductLanguage')?.value,
      CountryId: parseInt(
        this.formMarketParam.get('General').get('CountryId')?.value
      ),
      ScobProductTypeId: parseInt(
        this.formMarketParam.get('General').get('ScobProductTypeId')?.value
      ),
      UpdatedBy: this.formMarketParam.get('General').get('UpdatedBy')?.value,
      LastUpdated: this.formMarketParam.get('General').get('LastUpdated')
        ?.value,

      Specification: {
        System: this.formMarketParam.get('Specification').get('System')?.value,
        Screen: this.formMarketParam.get('Specification').get('Screen')?.value,
        MarketId: parseInt(
          this.formMarketParam.get('Specification').get('MarketId')?.value
        ),
        MarketName: this.formMarketParam.get('Specification').get('MarketName')
          ?.value,
        OrderMarket: this.formMarketParam
          .get('Specification')
          .get('OrderMarket')?.value,
        IsBusinessUnit: this.formMarketParam
          .get('Specification')
          .get('IsBusinessUnit')?.value,
        IsoCountryCode: this.formMarketParam
          .get('Specification')
          .get('IsoCountryCode')?.value,
        LanguageId: parseInt(
          this.formMarketParam.get('Specification').get('LanguageId')?.value
        ),
        UserId: parseInt(
          this.formMarketParam.get('Specification').get('UserId')?.value
        ),
        DefaultViewId: parseInt(
          this.formMarketParam.get('Specification').get('DefaultViewId')?.value
        ),
        ConfigTypeId: parseInt(
          this.formMarketParam.get('Specification').get('ConfigTypeId')?.value
        ),
      },

      Price: {
        System: this.formMarketParam.get('Price').get('System')?.value,
        MarketCode: this.formMarketParam.get('Price').get('MarketCode')?.value,
        FactoryInvoiceTypeId: parseInt(
          this.formMarketParam.get('Price').get('FactoryInvoiceTypeId')?.value
        ),
        FactoryMarketTypeId: parseInt(
          this.formMarketParam.get('Price').get('FactoryMarketTypeId')?.value
        ),
        DealerTypeId: parseInt(
          this.formMarketParam.get('Price').get('DealerTypeId')?.value
        ),
        RetailTypeId: parseInt(
          this.formMarketParam.get('Price').get('RetailTypeId')?.value
        ),
      },
      IacobParameters: {
        MarketId: parseInt(
          this.formMarketParam.get('IacobParameters').get('MarketId')?.value
        ),
        ParameterId: parseInt(
          this.formMarketParam.get('IacobParameters').get('ParameterId')?.value
        ),
      },
    };

    this.marketParameters.create(request).subscribe({
      next: (result) => {
        if (result.Success) {
          this.shared.successAlert(
            this.shared.translate('Market parameter created successfully!')
          );
          this.getMarketParameters();
          modal.closeModal();
        }
      },
      error: (error) => {
        this.shared.errorAlert(
          this.shared.translate('Error while creating new Market Parameters!')
        );
      },
    });
  }

  update(modal: any) {
    if (!this.formMarketParam.valid) {
      this.formMarketParam.markAllAsTouched();
      this.shared.errorAlert(
        this.shared.translate('Please, fill all the required fields!')
      );
      return;
    }

    var request = {
      Id: parseInt(this.formMarketParam.get('General').get('Id')?.value),
      ProductTypeId: parseInt(
        this.formMarketParam.get('General').get('ProductTypeId')?.value
      ),
      DefaultCurrencyCode: this.formMarketParam
        .get('General')
        .get('DefaultCurrencyCode')?.value,
      System: this.formMarketParam.get('General').get('System')?.value,
      ProductLanguage: this.formMarketParam
        .get('General')
        .get('ProductLanguage')?.value,
      CountryId: parseInt(
        this.formMarketParam.get('General').get('CountryId')?.value
      ),
      ScobProductTypeId: parseInt(
        this.formMarketParam.get('General').get('ScobProductTypeId')?.value
      ),
      UpdatedBy: this.formMarketParam.get('General').get('UpdatedBy')?.value,
      LastUpdated: this.formMarketParam.get('General').get('LastUpdated')
        ?.value,

      Specification: {
        System: this.formMarketParam.get('Specification').get('System')?.value,
        Screen: this.formMarketParam.get('Specification').get('Screen')?.value,
        MarketId: parseInt(
          this.formMarketParam.get('Specification').get('MarketId')?.value
        ),
        MarketName: this.formMarketParam.get('Specification').get('MarketName')
          ?.value,
        OrderMarket: this.formMarketParam
          .get('Specification')
          .get('OrderMarket')?.value,
        IsBusinessUnit: this.formMarketParam
          .get('Specification')
          .get('IsBusinessUnit')?.value,
        IsoCountryCode: this.formMarketParam
          .get('Specification')
          .get('IsoCountryCode')?.value,
        LanguageId: parseInt(
          this.formMarketParam.get('Specification').get('LanguageId')?.value
        ),
        UserId: parseInt(
          this.formMarketParam.get('Specification').get('UserId')?.value
        ),
        DefaultViewId: parseInt(
          this.formMarketParam.get('Specification').get('DefaultViewId')?.value
        ),
        ConfigTypeId: parseInt(
          this.formMarketParam.get('Specification').get('ConfigTypeId')?.value
        ),
      },

      Price: {
        System: this.formMarketParam.get('Price').get('System')?.value,
        MarketCode: this.formMarketParam.get('Price').get('MarketCode')?.value,
        FactoryInvoiceTypeId: parseInt(
          this.formMarketParam.get('Price').get('FactoryInvoiceTypeId')?.value
        ),
        FactoryMarketTypeId: parseInt(
          this.formMarketParam.get('Price').get('FactoryMarketTypeId')?.value
        ),
        DealerTypeId: parseInt(
          this.formMarketParam.get('Price').get('DealerTypeId')?.value
        ),
        RetailTypeId: parseInt(
          this.formMarketParam.get('Price').get('RetailTypeId')?.value
        ),
      },
      IacobParameters: {
        MarketId: parseInt(
          this.formMarketParam.get('IacobParameters').get('MarketId').value
        ),
        ParameterId: parseInt(
          this.formMarketParam.get('IacobParameters').get('ParameterId').value
        ),
      },
    };

    this.marketParameters.update(request.Id, request).subscribe({
      next: (result) => {
        if (result.Success) {
          this.shared.successAlert(
            this.shared.translate('Market parameter updated successfully!')
          );
          modal.closeModal();
        }
      },
      error: (error) => {
        this.shared.errorAlert(
          this.shared.translate('Error while updating new Market Parameters!')
        );
      },
    });
  }

  deleteMarketParameter(parameterId: number) {
    this.shared
      .warningOptionAlert(
        this.shared.translate('Are you sure you want to remove this item?')
      )
      .then((response) => {
        if (response.isConfirmed) {
          this.marketParameters.delete(parameterId).subscribe({
            next: (result) => {
              this.marketParameterList.splice(
                this.marketParameterList.findIndex((p) => p.Id == parameterId),
                1
              );
              this.shared.successAlert(
                this.shared.translate('Item removed successfully!')
              );
            },
            error: (error) => {
              this.shared.errorAlert(
                this.shared.translate('Error while removing Exchange Rate!')
              );
            },
          });
        }
      });
  }
}
